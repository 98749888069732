import Pino from 'pino';
import { Scrub } from './scrub';

const scrub = Scrub('***', [
  'ssn',
  'notes',
  'idToken',
  'id_token',
  'access_token',
  'image',
  'payload.customer.name',
  'payload.customer.lastName',
  'payload.customer.email',
  'payload.customer.phone',
  'payload.customer.birthdate'
]);

export const pino = Pino({
  level: 'info',
  messageKey: 'message',
  mixin(ctx, level) {
    switch (level) {
      case 10:
      case 20:
        return { severity: 'DEBUG', ctx };
      case 30:
        return { severity: 'INFO', ctx };
      case 40:
        return { severity: 'WARNING', ctx };
      case 50:
        return { severity: 'ERROR', ctx };
      case 60:
      default:
        return { severity: 'CRITICAL', ctx };
    }
  },
  mixinMergeStrategy(_, mixinObject: any) {
    const { severity, ctx } = mixinObject;
    return {
      severity,
      ...scrub(ctx),
    };
  },
});

export default pino;
